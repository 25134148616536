import React, { Component } from 'react';
import './style.scss';


export default class ContactIcon extends Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			width: window.innerWidth
		};
	}
	componentWillMount() {
		window.addEventListener('resize', this.handleWindowSizeChange);
	}
	// make sure to remove the listener
	// when the component is not mounted anymore
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
	}
	handleWindowSizeChange = () => {
		this.setState({ width: window.innerWidth });
	};

	render() {
		return (
			<>
					<div className="buttonWrapper">
						<div className="buttonContainer">
							<a href="tel:0768167947">
								<button className="ui circular phone button">
									<i className="phone icon" />
								</button>
							</a>
						</div>
                        {/* {this.state.width > 599 && ( */}
						<div className="buttonContainer">
							<a href="mailto:info@physiowerk-schmitt.ch?subject=Terminanfrage">
								<button className="ui circular mail button" tabIndex="0">
									<i className="mail icon" />
								</button>
							</a>
						</div>
						<div className="buttonContainer">
							<a href="https://onlinecalendar.medidoc.ch/BookAppointment?cgid=3rBGcYspd0qOMsdY7K1Aug" target="_top">
								<button className="ui circular onlineBooking button">
									<i className="calendar alternate outline icon" />
								</button>
							</a>
						</div>
                        {/* )} */}
						{/* <div className="buttonContainer">
							<a href="https://wa.me/41768167947">
								<button className="ui circular whatsapp button">
									<i className="whatsapp icon" />
								</button>
							</a>
						</div> */}
					</div>
			</>
		);
	}
}
