import React from 'react';
import './style.scss';
import { MyMenu } from '../Menu';
import { Footer } from '../Footer';
import { Helmet } from 'react-helmet';

export class Jobs extends React.Component {
	render() {
		return (
			<div id="app">
				<Helmet>
					<meta charSet="utf-8" />
					<title>Jobs | Physiotherapie & Craniosacral Therapie Winterthur</title>
					<meta name="description" content="Freie Stellen in unserer Physiopraxis in Winterthur." />
					<html lang="de" amp />
				</Helmet>
				<header className="App-header">
					<MyMenu />
				</header>
				<div id="jobs">
					<div className="ui vertical segment" style={{paddingTop: 'calc(1.5vw + 1.5em)'}}>
						<div className='section'>
              				<div className="ui text container">
                				<h2 className="center">Jobs – Offene Stellen</h2>
								<p>Zur Verstärkung unseres Teams suchen wir eine/n kompetente/n und motivierte/n <b className='bold violet'>Physiotherapeut/-in nach Vereinbarung</b>:</p>
             				</div>
            			</div>
					
						<div className="ui text container">
							<div className="jobAd">
								<h3>Physiotherapeut/-in 40-60%</h3>
								<div className="ui label violet">40-60%</div>
								<div className="ui label violet">Winterthur</div>
								<h4>Es erwartet dich:</h4>
								<ul>
									<li>
										eine helle, grosszügige und modern eingerichtete Praxis mit eigenem,
										geschlossenem Therapieraum auf dem neuesten Stand mit integriertem
										Trainingsbereich
									</li>
									<li>
										aufstrebendes Quartier in Winterthur Neuhegi (gut erschlossen mit ÖV, Parkplatz
										möglich, div. Möglichkeiten für Mittagessen)
									</li>
									<li>
										abwechslungsreiches Patientengut im Bereich Orthopädie, Chirurgie, Gynäkologie,
										Rheumatologie, Neurologie, Geriatrie
									</li>
									<li>motiviertes Team mit herzlicher Atmosphäre</li>
									<li>
										attraktive Anstellungsbedingungen (überdurchschnittliche Entlohnung, 5 Wochen Ferien, 1 Woche für Fortbildung,
										Supervisionen, interne Fortbildungen)
									</li>
									<li>Persönliche und berufliche Entwicklungsmöglichkeiten</li>
								</ul>
								<h4>Du bringst mit:</h4>
								<ul>
									<li>
										Abgeschlossene Ausbildung als Physiotherapeut/-in FH/HF oder gleichwertige
										Ausbildung mit SRK-Anerkennung
									</li>
									<li>
										Weiterbildungen in Manueller Therapie, Manueller Lymphdrainage, Faszienbehandlung, Dry Needling, Trainingstherapie, med. Massagen, Craniosacraltherapie oder das Interesse sich hierin weiterzubilden
									</li>
									<li>Freude an deiner Arbeit als Physiotherapeut/in</li>
									<li>Flexibilität, Zuverlässigkeit und Engagement</li>
									<li>selbstständiges und sorgfältiges Arbeiten (Physiotherapie, Administration)</li>
								</ul>
								<p>Haben wir dein Interesse geweckt?</p>
								<p>
									Dann freuen wir uns auf deine Bewerbung per Mail an{' '}
									<b className="bold violet">
									<a
										href="mailto:info@physiowerk-schmitt.ch?subject=Bewerbung Physiotherapeut/-in 40-60%"
										className="link"
									>
										info@physiowerk-schmitt.ch
									</a>
									</b>.
								</p>
								<p>
								Studienabgänger und Wiedereinsteiger sind herzlich willkommen!
								</p>
							</div>
						</div>
					</div>
				</div>
				<div>
					<Footer className="footer" />
				</div>
			</div>
		);
	}
}
