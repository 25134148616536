import React from 'react';
import { Link } from 'react-router-dom';
import ContactIcon from '../ContactIcon';
import './style.scss';

export class Footer extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			width: window.innerWidth
		};
	}
	componentWillMount() {
		window.addEventListener('resize', this.handleWindowSizeChange);
	}
	// make sure to remove the listener
	// when the component is not mounted anymore
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
	}
	handleWindowSizeChange = () => {
		this.setState({ width: window.innerWidth });
	};

	render() {
		return (
			<div className="ui vertical segment footer">
				<div className="ui container footer">
					<div className="mainRow">
						<div className="footerColumnOne">
							<div className="row">
								<div className="imageContainer">
									<img
										src={require('../imgs/physiowerk-logo-mobile-white.svg')}
										alt="PhysioWerk Logo"
									/>
								</div>

								<div className="addressContainer">
									<div className="address">
										PhysioWerk Schmitt<br />
										Sabine Schmitt<br />
										<span style={{ whiteSpace: 'nowrap' }}>Else-Züblin-Strasse 115</span>
										<br />
										8404 Winterthur
									</div>
									<div className="contact">
										<span style={{ whiteSpace: 'nowrap' }}>
											Mobil <a href="tel:0768167947">076 816 79 47</a>
										</span>
										<br />
										<span style={{ whiteSpace: 'nowrap' }}>
											<a href="mailto:info@physiowerk-schmitt.ch">info@physiowerk-schmitt.ch</a>
										</span>
									</div>
								</div>
							</div>
						</div>

						<div className="footerColumnTwo">
							<div>
								Termine nach Vereinbarung: <br />Sie können Ihren Termin telefonisch, per Mail oder online buchen.
							</div>

							<ContactIcon />
						</div>
					</div>
					<div className="copyright">
						© PhysioWerk Schmitt |{' '}
						<Link to="/impressum" replace>
							Impressum
						</Link>
					</div>
				</div>
			</div>
		);
	}
}
