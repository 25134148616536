import React from 'react';
import Dock from 'react-dock';
import { Menu, Button, Icon } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { browserCheck } from '@ta-interaktiv/browsercheck';
import ContactIcon from '../ContactIcon';

import './style.scss';

export class MyMenu extends React.Component<Props, State> {
	// state = { activeItem: '' }
	// handleItemClick = (e, { name }) => this.setState({ activeItem: name })

	constructor(props) {
		super(props);
		this.state = {
			menuOpen: false
		};

		this.closeMenu = this.closeMenu.bind(this);
		this.openMenu = this.openMenu.bind(this);
		this.handleMenuClick = this.handleMenuClick.bind(this);
	}

	handleMenuClick(e, { name }) {
		console.log('name');
	}

	openMenu() {
		// console.log('window.pageYOffset:', window.pageYOffset)
		// let yOffset = -window.pageYOffset;
		this.setState({ menuOpen: true });

		// When the modal is shown, we want a fixed body
		/*
		document.body.style.position = 'fixed'
		document.body.style.top = yOffset + 'px'
		*/
	}

	closeMenu() {
		this.setState({ menuOpen: false });
		// When the modal is hidden...
		/*
		const scrollY = document.body.style.top
		document.body.style.position = ''
		document.body.style.top = ''
		window.scrollTo(0, parseInt(scrollY || '0') * -1)
		*/
	}

	render() {
		// const { activeItem } = this.state

		return (
			<>
								
				<Menu text size="huge">
				<div className="ui container">
					<Menu.Item as={NavLink} to="/">
						<img
							className="mobile hidden"
							src={require('../imgs/physiowerk-logo.svg')}
							alt="PhysioWerk Logo"
						/>
						<img
							className="mobile only"
							src={require('../imgs/physiowerk-logo-mobile.svg')}
							alt="PhysioWerk Logo"
						/>
					</Menu.Item>

					<Menu.Menu position="right">
						{browserCheck.isMobile ? ( 
						
						<div className="menu-toggle">
							<Button onClick={this.openMenu} className={'burgerMenu'}>
							<div>Menu</div><Icon name="bars" />
							</Button>
						</div>) : (
						<>
						<Menu.Item
							as={NavLink}
							to="/angebot"
							name="angebot"
							content="Angebot"
							// active={activeItem === 'About'}
						/>

						<Menu.Item
							as={NavLink}
							to="/ueber-uns"
							name="Über uns"
							content="Über uns"
							// active={activeItem === 'About'}
						/>

						<Menu.Item
							as={NavLink}
							to="/galerie"
							name="Galerie"
							content="Galerie"
							// active={activeItem === 'About'}
						/>

						<Menu.Item
							as={NavLink}
							to="/jobs"
							name="Jobs"
							content="Jobs"
						/>

						<Menu.Item
							as={NavLink}
							to="/kontakt"
							name="kontakt"
							content="Kontakt"
							// active={activeItem === 'Contact'}
						/>

						<Menu.Item
							href="https://onlinecalendar.medidoc.ch/BookAppointment?cgid=3rBGcYspd0qOMsdY7K1Aug"
							target="_top"
							name="Online buchen"
							// content="Online Termine buchen"
							// active={activeItem === 'Contact'}
						><Icon name="calendar alternate outline"></Icon>Online buchen</Menu.Item>
						</>)}
						
					</Menu.Menu>
					</div>
				</Menu>

				<Dock
					position={'right'}
					size={1}
					dimMode="none"
					isVisible={this.state.menuOpen}
					dockStyle={{ backgroundColor: '#592677' }}
				>
					<div onClick={this.closeMenu} className="top-menu">
						<Menu className="vertical">
							{/* <Menu.Item
								as={NavLink}
								to="/"
								name="home"
								content="Home"
								className="nav-link"
								// active={activeItem === 'About'}
							/> */}

							<Menu.Item
								as={NavLink}
								to="/angebot"
								name="angebot"
								content="Angebot"
								className="nav-link"
								// active={activeItem === 'About'}
							/>

							<Menu.Item
								as={NavLink}
								to="/ueber-uns"
								name="Über uns"
								content="Über uns"
								className="nav-link"
								// active={activeItem === 'About'}
							/>

							<Menu.Item
								as={NavLink}
								to="/galerie"
								name="Galerie"
								content="Galerie"
								className="nav-link"
								// active={activeItem === 'About'}
							/>

							<Menu.Item
								as={NavLink}
								to="/jobs"
								name="Jobs"
								content="Jobs"
								className="nav-link"
							/>

							<Menu.Item
								as={NavLink}
								to="/kontakt"
								name="kontakt"
								content="Kontakt"
								className="nav-link"
								// active={activeItem === 'Contact'}
							/>
							<Menu.Item
								href="https://onlinecalendar.medidoc.ch/BookAppointment?cgid=3rBGcYspd0qOMsdY7K1Aug"
								target="_top"
								name="Online buchen"
								content="Online Termine buchen"
								className="nav-link"
								// active={activeItem === 'Contact'}
							><Icon name="calendar alternate outline"></Icon>Online buchen</Menu.Item>

							<Button onClick={this.closeMenu} attched="right" className="closer">
								<Icon name="close" />
							</Button>

							<ContactIcon></ContactIcon>
						</Menu>
					</div>
				</Dock>
				
			</>
		)
	}
}
