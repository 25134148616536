import React from 'react'
import './styles.scss'

export default class chart extends React.Component<Props> {
  constructor (props) {
    super(props)
    this.state = {
      isExpanded: false
    }
    this.toggleMore = this.toggleMore.bind(this)
  }

//   trackMoreClick (targetLink) {
//     // GA tracking
//     let clickedPerson = targetLink.getAttribute('data-person')
//     console.log('person:' + clickedPerson)
//     window.dataLayer = window.dataLayer || []
//     window.dataLayer.push({
//       event: 'Interactions',
//       event_action: 'click',
//       event_label: 'Porträt: ' + clickedPerson
//     })
//   }

  toggleMore (e) {
    e.preventDefault()
    if (this.state.isExpanded) {
      this.setState({
        isExpanded: false
      })
    } else {
      // GA tracking
    //   let clickedPerson = e.currentTarget.getAttribute('data-person')
    //   console.log('person:' + clickedPerson)
    //   window.dataLayer = window.dataLayer || []
    //   window.dataLayer.push({
    //     event: 'Interactions',
    //     event_action: 'click',
    //     event_label: 'Porträt: ' + clickedPerson
    //   })
      this.setState({
        isExpanded: true
      })
    }
  }

  render () {
    return (
      <div className='chart-wrapper' onClick={this.toggleMore}>
        <h3>{this.props.title} <div dangerouslySetInnerHTML={{__html: this.props.label}} /></h3>
        <div
          className={
            this.state.isExpanded
              ? 'chart-info expanded'
              : 'chart-info collapsed'
          }
        >
          <div className='intro-text'>
            <p dangerouslySetInnerHTML={{__html: this.props.shortText}} />
          </div>
          <div className='show-more'>
            <button className='ui button toggleText'>
              mehr lesen <i className='chevron right icon small' />
            </button>
          </div>
          <div className='additional-text'>
            <p dangerouslySetInnerHTML={{__html: this.props.longText}} />
          </div>
          <div className='show-less'>
            <button className='ui button toggleText'>
              weniger <i className='chevron up icon small' />
            </button>
          </div>
        </div>
      </div>
    )
  }
}
