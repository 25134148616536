import React from "react";
// import ReactDOM from 'react-dom';
import { render } from "react-snapshot";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Home } from "./components/Home";
import { About } from "./components/About";
import { Angebot } from "./components/Angebot";
import { Galerie } from "./components/Galerie";
import { Contact } from "./components/Contact";
import { Impressum } from "./components/impressum";
import { Jobs } from "./components/Jobs";
import ScrollToTop from "./components/ScrollToTop";
import { Helmet } from "react-helmet";

import "semantic-ui-css/semantic.min.css";
import "./index.scss";

class Root extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Physiotherapie & Craniosacral Therapie | Winterthur
          </title>
          <meta
            name="description"
            content="Moderne Praxis für ganzheitliche Physiotherapie, Craniosacral Therapie, Massage und Lymphdrainage in Winterthur Neuhegi. Krankenkassen anerkannt."
          />
          <html lang="de" amp />
        </Helmet>

        <Router basename={""}>
          <ScrollToTop>
            <Switch>
                <Route exact path="/" component={Home} />
                {/* <Route exact path="/" render={(props) => <Home projectData={this.state.listOfProjects} loaderState={this.state.request} {...props} />} /> */}
                <Route exact path="/kontakt" component={Contact} />
                <Route exact path="/angebot" component={Angebot} />
                <Route exact path="/galerie" component={Galerie} />
                <Route exact path="/jobs" component={Jobs} />
                <Route exact path="/ueber-uns" component={About} />
                <Route exact path="/impressum" component={Impressum} />
                {/* <Route path="/projects/:id" render={(props) => <Projects projectData={this.state.listOfProjects} {...props} />} /> */}
                <Route
                  render={() => (
                    <div
                      style={{
                        width: "100vw",
                        height: "100vh",
                        margin: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                      }}
                    >
                      <img
                        style={{ maxWidth: "100px" }}
                        src={require("./components/imgs/physiowerk-logo-mobile.svg")}
                        alt="PhysioWerk Logo"
                      />
                    <h2>Ups: 404 Error</h2>
                    </div>
                  )}
                />
            </Switch>
          </ScrollToTop>
        </Router>
      </>
    );
  }
}

render(<Root />, document.getElementById("root"));
