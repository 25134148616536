import React from 'react';
import './styles.scss';
import L from 'leaflet';
import { Map, Marker, Popup, TileLayer, GeoJSON, ScaleControl, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './styles.scss';
// import styled from 'styled-components'
import Control from 'react-leaflet-control';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

// export const pointerIcon2 = new L.Icon({
//   iconUrl: require('../main/imgs/pin.svg'),
//   iconRetinaUrl: require('../main/imgs/pin.svg'),
//   iconAnchor: [14, 49],
//   popupAnchor: [0, -40],
//   iconSize: [25, 55],
//   // shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
//   shadowSize: [68, 95],
//   shadowAnchor: [20, 92],
//   className: 'icon'
// })

// const firstCorner = [47.3595, 8.5599]
// const lastCorner = [47.35054, 8.60181]

export default class trackMap extends React.Component {
	startViewport = null;

	constructor(props) {
		super(props);
		this.state = {
			isMobile: window.innerWidth < 800,
			maxZoom: 20,
			minZoom: 9,
			viewport: {}
		};

		this.trackPopupClicks = this.trackPopupClicks.bind(this);
	}

	trackPopupClicks(spot) {
		window.dataLayer = window.dataLayer || [];

		window.dataLayer.push({
			event: 'Interactions',
			event_action: 'click',
			event_label: 'Tipp: ' + spot.name
		});
	}

	getStyle(feature) {
		return {
			weight: 3.5,
			opacity: 1,
			color: '#AD427F',
			dashArray: '0'
		};
	}

	saveStartViewport = (viewport) => {
		if (this.startViewport === null) {
			this.startViewport = viewport;
		}
	};

	// const firstCorner = [this.props.latFirstCorner, this.props.lngFirstCorner]
	// const lastCorner = [this.props.latLastCorner, this.props.lngLastCorner]

	init(ref) {
	  if (ref) {
	    ref.leafletElement.openPopup()
	  }
	}

	render() {
		const position = [ this.props.lat, this.props.lng ];
		const startPosition = [ this.props.markerPositionLat, this.props.markerPositionLng ];

		// const listHotspots = [
		//   { lat: 47.60577, lng: 8.60664, name: 'Kyburg'  },
		//   { lat: 47.60177, lng: 8.60964, name: 'Tülersee' }
		// ]

		// const mobileZoom = this.props.mobileZoom;
		// const desktopZoom = this.props.desktopZoom;

		const bounds = L.latLngBounds([ this.props.firstCorner, this.props.lastCorner ]).pad(0.1);

		// console.log(this.props)

		// listHotspots={[
		//   { lat: 47.522053, lng: 8.525667, name: 'Minigolf-Anlage Hirslen' }
		// ]}

		const Bus = this.props.listBus || [];
		const Train = this.props.listTrain || [];
		return (
			<Map
				onViewportChanged={this.saveStartViewport}
				viewport={this.state.viewport}
				scale={position}
				bounds={bounds}
				zoomControl={false}
				center={position}
				tap={false}
				maxZoom={this.state.maxZoom}
				minZoom={this.state.minZoom}
				dragging={this.state.isMobile ? false : true}
				touchZoom={true}
			>
				<TileLayer
					attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> PhysioWerk Schmitt"
					url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
				/>

				{Train.map((spot, index) => (
					<Marker
						key={index}
						position={L.latLng(spot.lat, spot.lng)}
						icon={this.props.iconTrain}
						onClick={() => {
							this.trackPopupClicks(spot);
						}}
					>
						<Popup>{spot.name}</Popup>
					</Marker>
				))}

				{Bus.map((spot, index) => (
					<Marker
						key={index}
						position={L.latLng(spot.lat, spot.lng)}
						icon={this.props.iconBus}
						onClick={() => {
							this.trackPopupClicks(spot);
						}}
					>
						<Popup>{spot.name}</Popup>
					</Marker>
				))}

				<Marker position={startPosition} icon={this.props.iconStart} ref={this.init}>
					<Popup>
						<div className="popup">
							<span className="bold" style={{ whiteSpace: 'nowrap' }}>
								PhysioWerk Schmitt
							</span>
							<br />
							<span style={{ whiteSpace: 'nowrap' }}>Else-Züblin-Str. 115</span>
							<br />
							<span style={{ whiteSpace: 'nowrap' }}>8404 Winterthur</span><br />
              <span className='space' style={{ whiteSpace: 'nowrap' }}>Mobil 076 816 79 47</span><br />
              <span style={{ whiteSpace: 'nowrap' }}>info@physiowerk-schmitt.ch</span>
						</div>
					</Popup>
				</Marker>

				<ScaleControl position="bottomleft" metric={true} imperial={false} />
				<ZoomControl position="topright" />

				<GeoJSON data={this.props.track} className={this.props.getStyle} />
				<Control position="topleft">
					<button onClick={() => this.setState({ viewport: { ...this.startViewport } })}>Zentrieren</button>
				</Control>
			</Map>
		);
	}
}
