import React from "react";
import Gallery from "react-photo-gallery";
import Measure from "react-measure";
import Lightbox from "react-images-extended";
import "./style.scss";

const photos = [
  {
    src: `${require('../imgs/praxis-physiotherapie-winterthur/praxis-aussen.jpg')}`,
    width: 5,
    height: 4
  },
  {
    src: `${require('../imgs/praxis-physiotherapie-winterthur/praxis-empfang.jpg')}`,
    width: 5,
    height: 4
  },
  {
    src: `${require('../imgs/praxis-physiotherapie-winterthur/praxisraeume.jpg')}`,
    width: 3,
    height: 4
  },
  {
    src: `${require('../imgs/praxis-physiotherapie-winterthur/praxisraum.jpg')}`,
    width: 3,
    height: 4
  },
  {
    src: `${require('../imgs/praxis-physiotherapie-winterthur/praxisraum-2.jpg')}`,
    width: 3,
    height: 4
  },
  {
    src: `${require('../imgs/praxis-physiotherapie-winterthur/trainingsraum-weitwinkel.jpg')}`,
    width: 5,
    height: 4
  },
  {
    src: `${require('../imgs/praxis-physiotherapie-winterthur/trainingsraum-geraete.jpg')}`,
    width: 5,
    height: 4
  },
];

const customStyles = {
  button: {
    height: '40px'
  },
  arrow: {
    color: 'white',
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  figure: {
    width: '100vw'
  },
	image: {
    '@media (max-width: 767px)': {
			width: '100%'
		},
    
		// maxWidth: '100vw',
	},
  // content: {
  //   display: 'flex',
  //   flexDirection: 'column',

  // },
  close: {
    marginRight: '0',
    height: '46px',
    width: '46px',
    padding: '13px'
  },
  footer: {
    bottom: '40px',
  },
  footerCount: {
    marginRight: '2em',
    '@media (max-width: 599px)': {
      marginRight: '1.25em',
    }
  },
  header: {
    marginRight: '1em',
    marginTop: '1em',
    '@media (max-width: 599px)': {
			// marginTop: '6vh',
      borderRadius: '50%',
      background: '#ffffff82',
      position: 'absolute',
      zIndex: '1000',
      height: '46px',
      width: '46px',
      bottom: '40px',
      left: '50%',
      marginLeft: '-23px'
		}
  }
}


export class DynamicGallery extends React.Component {
  constructor() {
    super();
    this.state = { width: -1 };
    this.state = { currentImage: 0 };
    this.state = {lightboxIsOpen: false};
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  }
  render() {
    const width = this.state.width;
    return (
      <Measure
        bounds
        onResize={contentRect =>
          this.setState({ width: contentRect.bounds.width })
        }
      >
        {({ measureRef }) => {
          if (width < 1) {
            return <div ref={measureRef} />;
          }
          let columns = 1;
          if (width >= 480) {
            columns = 2;
          }
          if (width >= 1024) {
            columns = 3;
          }
          if (width >= 1824) {
            columns = 4;
          }
          return (
            <div ref={measureRef} >
              <Gallery
                photos={photos}
                columns={columns}
                onClick={this.openLightbox}
              />
              <Lightbox
                images={photos}
                onClose={this.closeLightbox}
                onClickPrev={this.gotoPrevious}
                onClickNext={this.gotoNext}
                currentImage={this.state.currentImage}
                isOpen={this.state.lightboxIsOpen}
                width={3000}
                theme={customStyles}
          
              />
            </div>
          );
        }}
      </Measure>
    );
  }
}
