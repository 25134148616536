import React from "react";
import "./style.scss";
import { MyMenu } from "../Menu";
import { Footer } from "../Footer";
import { Helmet } from "react-helmet";

export class Impressum extends React.Component {
  render() {
    return (
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Impressum | Physiotherapie PhysioWerk Schmitt Winterthur
          </title>
          <meta
            name="description"
            content="Sie finden hier sämtliche Informationen zum Impressum von PhysioWerk Schmitt – Ihre Praxis für Physiotherapie & Craniosacral Therapie in Winterthur."
          />
          <html lang="de" amp />
        </Helmet>
        <header className="App-header">
          <MyMenu />
        </header>
        <div className="ui vertical segment">
          <div className="ui text container">
            <h2>Impressum</h2>
          </div>
          <div className="ui text container impressumContent">
            <h3 className="impressum">Inhalt</h3>
            <p>
              PhysioWerk Schmitt
              <br />
              Sabine Schmitt
              <br />
              Else-Züblin-Strasse 115
              <br /> 8404 Winterthur
            </p>

            <h3 className="impressum">Haftungsausschluss</h3>
            <p>
              Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen
              Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und
              Vollständigkeit der Informationen. Haftungsansprüche gegen den
              Autor wegen Schäden materieller oder immaterieller Art, welche aus
              dem Zugriff oder der Nutzung bzw. Nichtnutzung der
              veröffentlichten Informationen, durch Missbrauch der Verbindung
              oder durch technische Störungen entstanden sind, werden
              ausgeschlossen.
            </p>
            <p>
              Alle Angebote sind unverbindlich. Der Autor behält es sich
              ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne
              gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder
              die Veröffentlichung zeitweise oder endgültig einzustellen.
            </p>
            <h3 className="impressum">Haftung für Links</h3>
            <p>
              Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres
              Verantwortungsbereichs. Es wird jegliche Verantwortung für solche
              Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten
              erfolgen auf eigene Gefahr des Nutzers oder der Nutzerin.
            </p>
            <h3 className="impressum">Fotos</h3>
            <p>Toa Heftiba/Unsplash, Chase Kinney/Unsplash</p>
            <h3 className="impressum">Urheberrechte</h3>
            <p>
              Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos
              oder anderen Dateien auf der Website gehören ausschliesslich
              PhysioWerk Schmitt oder den speziell genannten Rechtsinhabern. Für
              die Reproduktion jeglicher Elemente ist die schriftliche
              Zustimmung der Urheberrechtsträger im Voraus einzuholen.
            </p>
          </div>
        </div>
        <div>
          <Footer className="footer" />
        </div>
      </div>
    );
  }
}