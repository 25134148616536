import React from 'react';
import { MyMenu } from '../Menu';
import { Footer } from '../Footer';
import TrackMap from '../../components/TrackMap';
import L from 'leaflet';
import { Helmet } from 'react-helmet';

import './style.scss';

export const iconLeicht = new L.Icon({
	iconUrl: require('../imgs/physiowerk-logo-mobile.svg'),
	iconRetinaUrl: require('../imgs/physiowerk-logo-mobile.svg'),
	iconAnchor: [ 13, 40 ],
	popupAnchor: [ 0, -25 ],
	iconSize: [ 26, 80 ],
	// shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
	shadowSize: [ 68, 95 ],
	shadowAnchor: [ 20, 92 ],
	className: 'icon'
});

export const iconBus = new L.Icon({
	iconUrl: require('../imgs/icon-bus.svg'),
	iconRetinaUrl: require('../imgs/icon-bus.svg'),
	iconAnchor: [ 6, 25 ],
	popupAnchor: [ 0, -16 ],
	iconSize: [ 12, 50 ],
	// shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
	shadowSize: [ 68, 95 ],
	shadowAnchor: [ 20, 92 ],
	className: 'iconBus'
});

export const iconTrain = new L.Icon({
	iconUrl: require('../imgs/icon-train.svg'),
	iconRetinaUrl: require('../imgs/icon-train.svg'),
	iconAnchor: [ 6, 25 ],
	popupAnchor: [ 0, -16 ],
	iconSize: [ 12, 50 ],
	// shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
	shadowSize: [ 68, 95 ],
	shadowAnchor: [ 20, 92 ],
	className: 'iconTrain'
});

export class Contact extends React.Component {
	render() {
		return (
			<div className="App">
				<Helmet>
					<meta charSet="utf-8" />
					<title>Kontakt | Lageplan und Adresse der Physiotherapie in Winterthur</title>
					<meta
						name="description"
						content="Unsere moderne Praxis für Physiotherapie & Craniosacral Therapie befindet sich im Zentrum von Winterthur Neuhegi. Rufen Sie uns an oder buchen Sie online."
					/>
					<html lang="de" amp />
				</Helmet>
				<header className="App-header">
					<MyMenu />
				</header>

				<TrackMap
					lng={8.763266}
					lat={47.504198}
					firstCorner={[ 47.50751, 8.7605 ]}
					lastCorner={[ 47.50107, 8.76658 ]}
					mobileZoom={13}
					desktopZoom={15}
					// track={this.props.track}
					markerPositionLat={47.504158}
					markerPositionLng={8.763323}
					// getStyle={mittelTrack}
					iconStart={iconLeicht}
					iconBus={iconBus}
					iconTrain={iconTrain}
					listBus={[
						{
							lat: 47.502483,
							lng: 8.764607,
							name: 'Haltestelle Else-Züblin (Linie 7)'
						},
						{
							lat: 47.50108,
							lng: 8.762007,
							name: 'Haltestelle Industriepark (Linie 5)'
						}
					]}
					listTrain={[
						{
							lat: 47.507969,
							lng: 8.760329,
							name: 'Bahnhof Oberwinterthur (S11, S14, S24, S29, S30)'
						},
						{ lat: 47.501432, lng: 8.768987, name: 'Bahnhof Hegi (S35)' }
					]}
				/>

				<div className="ui vertical segment content" id="contact">
					{/* <div className="ui text container">
						<h2>Anfahrtsweg</h2>
					</div> */}
					<div className='section'>
                        <div className='ui text container'>
							<h2 className="center">Lage</h2>
							<p>Unsere Gesundheitspraxis liegt im Herzen des urbanen Stadtquartiers Winterthur Neuhegi (Stadtkreis Oberwinterthur) direkt am Eulachpark. Sie ist mit dem öffentlichem Verkehr und Auto sehr gut erreichbar und befindet sich im Erdgeschoss. Der Zugang ist hindernisfrei mit dem Rollstuhl möglich.</p>
						</div>
					</div>
					<div className="section">
						<div className="ui container icons">
							<div className="ui three column grid stackable">
								<div className="row">
									<div className="column way">
										<div className="wrapper">
											{' '}
											<i className="car icon" />
											<p className="cellTitle bold">Auto</p>
										</div>

										<p className="cellText">
											Kostenlose Parkmöglichkeiten direkt vor der Praxis in der blauen Zone
										</p>
									</div>
									<div className="column way">
									<div className="wrapper">
											{' '}
											<i className="bus icon" />
											<p className="cellTitle bold">Bus</p>
										</div>
										<p className="cellText">
											Linie Nr. 7 bis Haltestelle Else-Züblin (3 Gehminuten zur Praxis) <br /> Linie Nr.
											5 bis Haltestelle Industriepark (6 Gehminuten zur Praxis)
										</p>
									</div>
									<div className="column way">
									<div className="wrapper">
											{' '}
											<i className="train icon" />
											<p className="cellTitle bold">Bahn</p>
										</div>
										<p className="cellText">
											Bahnhof Oberwinterthur (7 Gehminuten zur Praxis) <br />Bahnhof Hegi (7 Gehminuten
											zur Praxis)
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="section" id="covidNote">
						<div className='ui text container'>
							<div className="ui one column grid raised segment covidNote">
								<div className="column">
									{/* <div class="ui segment"> */}
									<div className="ui ribbon label special">Covid-19: So schützen wir uns</div>
									<div style={{ paddingTop: '1rem' }}>
										<ul className="ui list">
											{/* <li value="-" className="note">
												Bei uns tragen alle eine Gesichtsmaske
											</li> */}
											<li value="-" className="note">
												Wir waschen/desinfizieren unsere Hände
											</li>
											<li value="-" className="note">
												Nach jeder Behandlung werden die Oberflächen desinfiziert
											</li>
											{/* <li value="-" className="note">
												Wir verzichten auf das Händeschütteln
											</li> */}
											<li value="-" className="note">
												Wo möglich halten wir Abstand
											</li>
											<li value="-" className="note">
												Wir bleiben Zuhause, wenn wir uns krank fühlen, Husten oder Fieber haben
												oder wenn wir in den letzten zwei Wochen Kontakt mit einer COVID19 positiven
												Person hatten
											</li>
										</ul>
									</div>
									{/* <p style={{ paddingTop: '2rem', marginBottom: '1rem' }}>
										Ich freue mich Ihnen so das Therapieangebot aufrechterhalten zu können, mit der
										Einhaltung der BAG-Richtlinien und der Physioswiss-Empfehlungen.
									</p> */}
									{/* </div> */}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div>
					<Footer className="footer" />
				</div>
			</div>
		);
	}
}
