import React from 'react';
import { MyMenu } from '../Menu';
import { Footer } from '../Footer';
import { Helmet } from 'react-helmet';
import './style.scss';

export class About extends React.Component {
	render() {
		return (
			<div className="App">
				<Helmet>
					<meta charSet="utf-8" />
					<title>Über uns | Das Team der Physiotherapie PhysioWerk Schmitt</title>
					<meta
						name="description"
						content="Erfahren Sie hier mehr über unsere kompetenten Physiotherapeut:innen bei PhysioWerk Schmitt in Winterthur. Unser gemeinsames Ziel – Ihre Gesundheit."
					/>
					<html lang="de" amp />
				</Helmet>
				<header className="App-header">
					<MyMenu />
				</header>

				<div className="ui vertical fitted segment" style={{paddingTop: 'calc(1vw + 1.5em)'}}>
					<div className="ui container">
						<div className="ui grid relaxed stackable">
							<div className="two column row">
								<div className="six wide column">
									{/* <div className="ui vertical fitted segment content">
									<div className="ui text container image"> */}
									<div className="imageWrapper">
										<img
											src={require('../imgs/sabine-schmitt.jpg')}
											alt="Porträt Sabine Schmitt"
											className="ui centered image"
										/>
									</div>
									<h2 className="name">Sabine Schmitt</h2>
									<h3 className="jobtitle">
										Dipl. Physio- und Craniosacral&shy;therapeutin, Inhaberin
									</h3>
									{/* </div>
								</div> */}
								</div>
								<div className="ten wide column">
									{' '}
									<p>
										Es begeistert mich, scheinbar komplexe Umstände und Zusammenhänge so zu
										erläutern, dass es für mein Gegenüber verständlich ist und in Erinnerung bleibt.
										Ich gebe mein Bestes, Sie in Ihrem Heilungsprozess zu unterstützen, Schmerzen
										und andere Phänomene verständlicher werden zu lassen, Ängste zu nehmen,
										Motivation zu wecken und die Rückkehr in Ihren Alltag zu vereinfachen.
									</p>
									<p>
										In den vergangenen 15 Jahren konnte ich meine therapeutischen Fertigkeiten in verschiedenen 
										Praxen verfeinern. Ich behandle Patienten nach Verletzungen oder unterschiedlichsten 
										Erkrankungen bis sie wieder fit für ihre Alltags- und Sportanforderungen sind. 
										Dabei lege ich meinen Fokus auf das funktionelle Training mit dem eigenen Körpergewicht. 
										Als Physiotherapeutin durfte ich zudem für mehrere Jahre den Nachwuchs des Schweizer 
										Handballnationalteams betreuen. Ein weiteres Anliegen ist mir die therapeutische 
										Begleitung von Frauen vor und nach der Geburt, speziell mit der Beckenbodenrehabilitation, 
										womit ich jungen Müttern wieder ein Stück Freiheit und Lebensfreude zurückgeben kann.

									</p>
									<p>
										Mittlerweile habe ich mir ein breites Spektrum an Wissen und Erfahrungen aus den verschiedensten 
										Fachbereichen aneignen können. Auch in Zukunft ist es mir wichtig sie bestmöglich und kompetent 
										auf ihrem persönlichen Genesungsweg zu begleiten, darum bilde ich mich ständig weiter, vertiefe 
										mich in Studien und tausche mich gerne mit Fachkollegen und Ärztenetzwerken aus.
									</p>

									<div className="ui biobox">
										<p className="bold">Sprachen:</p>
										<span className="course">Deutsch, Englisch</span>
										<br />
										<br />
										<p className="bold">Für Sie immer in Bewegung:</p>
										<span className="year">2020–2023</span>
										<br />
										<span className="course">Komplementär&shy;therapeutin mit Branchenzertifikat OdA KT, Methode Craniosacral Therapie</span>
										<br />
										<span className="year">2017–2019</span>
										<br />
										<span className="course">Dipl. Craniosacraltherapeutin</span>
										<br />
										<span className="year">2017</span>
										<br />
										<span className="course">Grundkurs der Fascien</span>
										<br />
										<span className="year">2008–2016</span>
										<br />
										<ul className="thin course">
											<li>Manuelle Lymphdrainage- und Ödemtherapie</li>
											<li>Manuelle Therapie Ganeo</li>
											<li>Therapie nach Dr. Terrier</li>
											<li>Kinesiotaping</li>
											<li>Sypoba-Instruktorin</li>
										</ul>
										<br />
										<span className="year">2005–2008</span>
										<br />
										<span className="course">Dipl. Physiotherapeutin</span>
									</div>
								</div>
							</div>

							<div className="two column row">
								<div className="six wide column">
									<div className="imageWrapper">
										<img
											src={require('../imgs/carolin-thuemmler.jpg')}
											alt="Porträt Carolin Thümmler"
											className="ui centered image"
										/>
									</div>
									<h2 className="name">Carolin Thümmler</h2>
									<h3 className="jobtitle">Dipl. Physiotherapeutin und Lymphtherapeutin</h3>
								</div>
								<div className="ten wide column">
									<p>
										Mit Menschen zusammen zu arbeiten und durch meine Leidenschaft ihnen zu helfen,
										viel es mir leicht mich für eine Ausbildung als Physiotherapeutin zu
										entscheiden. Meinen Abschluss habe ich 2005 in Deutschland gemacht, wo ich
										einige Jahre Berufserfahrung sammeln konnte. Dann hat es mich für die Arbeit auf
										ein Kreuzfahrtschiff verschlagen und ich konnte neben meiner Tätigkeit auch
										meiner Leidenschaft für das Reisen nachgehen, was mich wiederum 2013 in die
										schöne Schweiz geführt hat.
									</p>
									<div className="ui biobox">
										<p className="bold">Sprachen:</p>
										<span className="course">Deutsch, Englisch, Italienisch (A2 begonnen)</span>
										<br />
										<br />
										<p className="bold">Für Sie immer in Bewegung:</p>
										<span className="year">2018</span>
										<br />
										<span className="course">Kinesio Taping</span>
										<br />
										<span className="year">2015</span>
										<br />
										<span className="course">Sportphysio (DOSB anerkannt)</span>
										<br />
										<span className="year">2014</span>
										<br />
										<span className="course">Sling Training</span>
										<br />
										<span className="year">2007</span>
										<br />
										<span className="course">Manuelle Lymphdrainage</span>
										<br />
										<span className="year">2005</span>
										<br />
										<span className="course">Dipl. Physiotherapeutin</span>
									</div>
								</div>
							</div>

							<div className="two column row">
								<div className="six wide column">
									<div className="imageWrapper">
										<img
											src={require('../imgs/tamara-scherzinger.jpg')}
											alt="Porträt Tamara Scherzinger"
											className="ui centered image"
										/>
									</div>
									<h2 className="name">Tamara Scherzinger</h2>
									<h3 className="jobtitle">BSc in Physiotherapie</h3>
								</div>
								<div className="ten wide column">
									<p>
										Es bereitet mir grosse Freude, Menschen mit ihren persönlichen Geschichten und individuellen Körpern kennenzulernen. 
										Ich habe den Anspruch, die Ursache der Beschwerden zu finden und behandle nicht nur die auftretenden Symptome wie 
										Schmerzen, Verspannungen und Entzündungen. Denn oft führen  Kettenreaktionen im Körper dazu, dass sich die 
										ursprünglichen Probleme auf andere Körperteile übertragen. Die besuchten Kurse  der Osteopathieausbildung unterstützen 
										mich bei dieser Detektivarbeit und haben mir das Wissen über die Zusammenhänge zwischen dem Bewegungsapparat, den 
										Organen und dem kraniosacralen System vermittelt.
									</p>
									<p>
										Sehr gerne behandle ich Patienten mit neurologischen Erkrankungen wie Rückenmarksverletzungen, Multiple Sklerose (MS) 
										und Parkinson und kann dabei auf meine mehrjährigen Berufserfahrung im Zentrum für Paraplegie der Universitätsklinik 
										Balgrist zurückgreifen.
									</p>

									<div className="ui biobox">
										<p className="bold">Sprachen:</p>
										<span className="course">
											Deutsch und Englisch
										</span>
										<br />
										<br />
										<p className="bold">Für Sie immer in Bewegung:</p>
										<span className="year">2020</span>
										<br />
										<span className="course">Dry Needling Therapie</span>
										<br />
										<span className="year">2016</span>
										<br />
										<span className="course">Fascial Fitness</span>
										<br />
										<span className="year">2014</span>
										<br />
										<span className="course">Maitland Konzept</span>
										<br />
										<span className="year">2013</span>
										<br />
										<span className="course">Physiotherapie bei Querschnittlähmung</span>
										<br />
										<span className="year">2011</span>
										<br />
										<span className="course">BSc in Physiotherapie, ZHAW Winterthur</span>
									</div>
								</div>
							</div>


							<div className="two column row">
								<div className="six wide column">
									<div className="imageWrapper">
										<img
											src={require('../imgs/leana-baechtold.jpg')}
											alt="Porträt Leana Bächtold"
											className="ui centered image"
										/>
									</div>
									<h2 className="name">Leana Bächtold</h2>
									<h3 className="jobtitle">Naturheilpraktikerin TEN i.A., Berufsmasseurin, Yogalehrerin</h3>
								</div>
								<div className="ten wide column">
									<p>
										Der Mensch als ganzheitliches Wesen steht für mich im Zentrum jeder Behandlung. Als 
										Naturheilpraktikerin i.A. kombiniere ich sowohl schulmedizinisches Wissen, wie auch 
										jenes der jahrhundertealten Heilkunde. Ich nutze neben meinen eigenen Kräften auch 
										die der Natur in Form der Pflanzenheilkunde, Ernährungsberatung oder Massageanwendungen 
										mit Feuer, Wasser, Ölen oder Auflagen.
									</p>
									<p>
										Seit 2017 arbeite ich als selbstständige Yogalehrerin. Im Jahr 2020 begann ich das Studium zur 
										Naturheilpraktikerin in traditionell europäischer Naturheilkunde, welches ich Ende 2024 
										abschliessen werde.
									</p>
									<p>
										Ich darf mit diesem Studium nicht nur einen neuen Beruf erlernen, sondern meine Berufung leben. 
										Mit viel Freude, Verständnis und Überzeugung behandle ich meine Patientinnen und Patienten und 
										begleite und unterstütze sie auf ihrem ganz persönlichen Weg hin zu einer langfristigen Gesundheit.
									</p>
									<div className="ui biobox">
										<p className="bold">Sprachen:</p>
										<span className="course">
											Deutsch
										</span>
										<br />
										<br />
										<p className="bold">Für Sie immer in Bewegung:</p>
										<span className="year">2020–2024</span>
										<br />
										<span className="course">Dipl. Naturheilpraktikerin TEN</span>
										<br />
										<span className="year">2024</span>
										<br />
										<span className="course">Dipl. Phytotherapeutin</span>
										<br />
										<span className="year">2023</span>
										<br />
										<span className="course">
											<ul className="thin course">
												<li>Diagnostik (Iris, Zunge, Puls)</li>
												<li>Ausleitverfahren (Schröpfen, Blutegel, Baunscheidtieren)</li>
												<li>Anerkennung über die Krankenkassen-Zusatzversicherungen</li>
											</ul>
										</span>
										<br />
										<span className="year">2022</span>
										<br />
										<span className="course">Zert. Berufsmasseurin: klassische Massage, Fussreflexzonen Therapie</span>
										<br />
										<span className="year">2021</span>
										<br />
										<span className="course">Zert. Ernährungscoach</span>
										<br />
										<span className="year">2020</span>
										<br />
										<span className="course">Yoga in der Schwangerschaft</span>
										<br />
										<span className="year">2017</span>
										<br />
										<span className="course">Dipl. Yogalehrerin</span>
										
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* alte Version */}
				{/* <div className="ui vertical fitted segment content">
					<div className="ui text container image">
						<div className="imageWrapperPortrait">
							<img src={require('../imgs/sabine-schmitt.jpg')} alt="porträt sabine schmitt" />
						</div>
					</div>
					<div className="ui text container" style={{ paddingTop: '2em', paddingBottom: '1em' }}>
						<h2 className="name">Sabine Schmitt</h2>
						<h3 className="jobtitle">Dipl. Physio- und Craniosacral&shy;therapeutin</h3>
						<p>
							Es begeistert mich, scheinbar komplexe Umstände und Zusammenhänge so zu erläutern, dass es
							für mein Gegenüber verständlich ist und in Erinnerung bleibt. Ich gebe mein Bestes, Sie in
							Ihrem Heilungsprozess zu unterstützen, Schmerzen und andere Phänomene verständlicher werden
							zu lassen, Ängste zu nehmen, Motivation zu wecken und die Rückkehr in Ihren Alltag zu
							vereinfachen.
						</p>

						<p>
							In den vergangenen 10 Jahren konnte ich meine therapeutischen Fertigkeiten in verschiedenen
							Praxen verfeinern. Ich behandle Patienten nach Verletzungen oder unterschiedlichsten
							Erkrankungen bis sie wieder fit für ihre Alltags- und Sportanforderungen sind. Dabei lege
							ich meinen Fokus auf das funktionelle Training mit dem eigenen Körpergewicht. Als
							Physiotherapeutin betreute ich ausserdem für mehrere Jahre den Nachwuchs des Schweizer
							Handballnationalteams. Ein besonderes Anliegen ist mir die therapeutische Begleitung von
							Frauen vor und nach der Geburt, speziell mit der Beckenboden&shy;rehabilitation, womit ich
							jungen Müttern wieder ein Stück Freiheit und Lebensfreude zurückgeben kann.
						</p>

						<p>
							Mittlerweile habe ich mir ein breites Spektrum an Wissen und Erfahrungen aus den
							verschiedensten Fachbereichen angeeignet, womit ich meinen Patienten eine für sie
							bestmögliche, individuelle und ganzheitliche Beratung und Behandlung anbieten kann. Um Sie
							auch in Zukunft auf dem besten Weg der Genesung zu begleiten, bilde ich mich ständig weiter,
							vertiefe mich in Studien und tausche mich gerne mit Fachkollegen und Ärztenetzwerken aus.
						</p>
						<p>
							Liebe Grüsse<br />Sabine Schmitt
						</p>
						<div className="ui biobox">
							<p className="bold">Sprachen:</p>
							<span className="course">Deutsch, Englisch</span>
							<br />
							<br />
							<p className="bold">Für Sie immer in Bewegung:</p>
							<span className="year">2020–2023</span>
							<br />
							<span className="course">Komplementär&shy;therapeutin (i.A.)</span>
							<br />
							<span className="year">2017–2019</span>
							<br />
							<span className="course">Dipl. Craniosacraltherapeutin</span>
							<br />
							<span className="year">2017</span>
							<br />
							<span className="course">Grundkurs der Fascien</span>
							<br />
							<span className="year">2008–2016</span>
							<br />
							<ul className="thin course">
								<li>Manuelle Lymphdrainage- und Ödemtherapie</li>
								<li>Manuelle Therapie Ganeo</li>
								<li>Therapie nach Dr. Terrier</li>
								<li>Kinesiotaping</li>
								<li>Sypoba-Instruktorin</li>
							</ul>
							<br />
							<span className="year">2002–2008</span>
							<br />
							<span className="course">Dipl. Physiotherapeutin</span>
						</div>
					</div>
				</div> */}

				<div>
					<Footer className="footer" />
				</div>
			</div>
		);
	}
}

export default About;
