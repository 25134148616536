import React, { Component } from "react";
// import './App.css';
import { MyMenu } from "../Menu";
import Cover from "../cover";
import { Footer } from "../Footer";
import "./style.scss";
import { Helmet } from "react-helmet";


export class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOfProjects: []
    };
  }

  render() {
    return (
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Physiotherapie & Craniosacral Therapie | Winterthur
          </title>
          <meta
            name="description"
            content="Moderne Praxis für ganzheitliche Physiotherapie, Craniosacral Therapie, Massage und Lymphdrainage in Winterthur Neuhegi. Krankenkassen anerkannt."
          />
          <html lang="de" amp />
        </Helmet>
        <header className="App-header">
          <MyMenu />
          <Cover
            title="Physiotherapie und Craniosacral Therapie in Winterthur"
            subtitle="Ihre Gesundheitspraxis für"
          />
        </header>

        <div className="ui vertical segment">

         <div className='section'>
              <div className="ui text container">
                <h2 className="center noLead">Ihre Vorteile</h2>
              </div>
            </div>
     
            <div className='section'>
            <div className="ui container">
              <div className="ui relaxed grid stackable benefits">
                  <div className="sixteen wide mobile eight wide tablet eight wide computer column benefit" >
                    <div className='containerContent'>

                      <div className='textWrapper'>
                        <img
                          src={require("../imgs/icon-mensch-im-zentrum.svg")}
                          className="ui image fluid icon benefit"
                          alt="Icon Mensch im Zentrum"
                        />
                        <h3>Wir sehen den Menschen als Ganzes</h3>
                        <p>Ein ausführlicher Befund zu Beginn der Behandlung ist fester 
                          Bestandteil jeder Therapie. Dabei sehen wir den Menschen als Ganzes, 
                          nicht nur das einzelne Symptom. Für eine nachhaltige und bestmögliche Therapie 
                          beziehen wir deshalb stets auch personenbezogene Faktoren wie das soziale Umfeld, Vor- und Nebenerkrankungen 
                          und den Lebensstil des Patienten bei der therapeutischen Herangehensweise mit ein.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="sixteen wide mobile eight wide tablet eight wide computer column benefit">
                    <div className='containerContent'>

                      <div className='textWrapper'>
                        <img
                          src={require("../imgs/icon-kompetenzen.svg")}
                          className="ui image fluid icon benefit"
                          alt="Icon Kompetenzen"
                        />
                        <h3>Qualifiziertes Fachpersonal</h3>
                        <p>Unser qualifiziertes Team besteht aus erfahrenen Physiotherapeuten
                          mit hohen Sozialkompetenzen und einem breitem Know-How. All unsere Therapeuten behandeln nach neuesten wissenschaftlichen und medizinischen Erkenntnissen. Vor allem arbeiten wir gemeinsam mit Ihnen, unseren Patienten. Während der Zeit in der Sie bei uns in der Therapie sind, werden Sie bis zur letzten Sitzung eins-zu-eins begleitet und, sofern möglich, immer vom gleichen Therapeuten betreut.
                        </p>
                        <a href="/ueber-uns"
                          target="_top" className='inlineLink'>
                            Über&nbsp;uns&nbsp;<i className="long arrow alternate right icon" />
                          </a>
                      </div>
                    </div>
                  </div>
                  <div className="sixteen wide mobile eight wide tablet eight wide computer column benefit">
                    <div className='containerContent'>

                      <div className='textWrapper'>
                        <img
                          src={require("../imgs/icon-moderne-praxis.svg")}
                          className="ui image fluid icon benefit"
                          alt="Icon moderne Praxis"
                        />
                        <h3>Moderne Praxis</h3>
                        <p>Unsere Gesundheitspraxis für Physiotherapie und Craniosacral Therapie in Winterthur besteht aus modern eingerichteten Räumen für diagnostische und therapeutische Zwecke. Hohe Decken, Fenster und Oberlicht in jedem Raum sorgen für viel Helligkeit und eine freundliche Atmosphäre. Alle Therapieräume sind geschlossene Räume, die Ihnen die nötige Privatsphäre während der Behandlung garantieren. Stufenlos und mit Schwenktüren ausgestattet sind sie barrierefrei zugänglich.
                        </p>
                        <div>
                          <a href="/galerie"
                          target="_top" className='inlineLink'>
                            Zur&nbsp;Galerie&nbsp;<i className="long arrow alternate right icon" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sixteen wide mobile eight wide tablet eight wide computer column benefit">
                    <div className='containerContent'>

                      <div className='textWrapper'>
                        <img
                          src={require("../imgs/icon-trainingsraum.svg")}
                          className="ui image fluid icon benefit"
                          alt="Icon Trainingsraum"
                        />
                        <h3>Grosszügiger Trainingsraum</h3>
                        <p>In unserem Trainingsraum finden sich sämtliche Geräte für die Rehabilitation nach einer Verletzung, Krankheit und/oder Operation. Unseren Schwerpunkt legen wir auf das funktionelle Training, um mit ihnen möglichst alltagsnah üben zu können. Unser Trainingsraum steht Ihnen während der Behandlungszeit kostenlos zur Verfügung.
                        </p>
                        <div>
                          <a href="/galerie"
                          target="_top" className='inlineLink'>
                             Zur&nbsp;Galerie&nbsp;<i className="long arrow alternate right icon" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sixteen wide mobile eight wide tablet eight wide computer column benefit" style={{height: '100%'}}>
                    <div className='containerContent'>
                      <div className='textWrapper'>
                        <img
                          src={require("../imgs/icon-schnelle-termine.svg")}
                          className="ui image fluid icon benefit"
                          alt="Icon schnelle Termine"
                        />
                          <h3>Auch kurzfristige Termine</h3>
                          <p>Wir bieten Ihnen innerhalb der gleichen Woche den ersten Termin an. Zudem können Sie sich Ihre Termine bequem online buchen. Notfälle bekommen noch am selben Tag einen Termin, kontaktieren Sie uns hierfür bitte telefonisch unter <span className='contactInline' style={{ whiteSpace: 'nowrap' }}><a href="tel:0768167947">076 816 79 47</a>
										      </span>.
                          </p>
                          <div>
                          <a href="https://onlinecalendar.medidoc.ch/BookAppointment?cgid=3rBGcYspd0qOMsdY7K1Aug"
                          target="_top" className='inlineLink'>
                            Online&nbsp;Terminbuchung&nbsp;<i className="long arrow alternate right icon" />
                          </a>
                        </div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div className='section'>
            <div className="ui text container logos">
              <div className="contentWrapper">
                <p className="thin">Mitgliedschaften/Zertifizierungen</p>
                <div className="imageWrapper">
                  <a
                    href="https://www.physioswiss.ch"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../imgs/physio-swiss-logo-bw.jpg")}
                      className="ui image fluid cooperation"
                      style={{ maxWidth: "200px" }}
                      alt="Physioswiss Logo"
                    ></img>
                  </a>
                  <a
                    href="https://www.emr.ch"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require("../imgs/emr-logo-bw.jpg")}
                      className="ui image fluid cooperation"
                      style={{ maxWidth: "280px" }}
                      alt="EMR-Zertifizierung Logo"
                    ></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Footer className="footer" />
        </div>
      </div>
      
    );
  }
}

