import React from 'react';
import { MyMenu } from '../Menu';
import { Footer } from '../Footer';
import { Helmet } from 'react-helmet';
import { DynamicGallery } from "../DynamicGallery";
import './style.scss';

export class Galerie extends React.Component {
	render() {
		return (
			<div className="App">
				<Helmet>
					<meta charSet="utf-8" />
					<title>Impressionen | Physiotherapie & Craniosacral Therapie Winterthur</title>
					<meta
						name="description"
						content="Auf dieser Unterseite finden Sie einige Impressionen und Fotos von unserer Physiotherapie."
					/>
					<html lang="de" amp />
				</Helmet>
				<header className="App-header">
					<MyMenu />
				</header>

				<div className="ui vertical fitted segment" style={{paddingTop: 'calc(1.5vw + 1.5em)'}}>
                    <div className='section'>
                        <div className='ui text container'>
							<h2 className="center">Galerie</h2>
							<p>Die Praxis besteht aus modern eingerichteten Räumen für diagnostische und therapeutische Zwecke. Hohe Decken, Fenster und Oberlicht sorgen für Helligkeit und eine freundliche Atmosphäre. Sämtliche <b className='bold violet'>Räume sind geschlossen und barrierefrei</b> (stufenlos, Schwenktüren ohne Schwellen) zugänglich. Sie garantieren Ihnen die nötige Privatsphäre. In unserem grosszügig gestalteten <b className='bold violet'>Trainingsraum</b> trainieren Sie mit modernen medizinischen Geräten. Er bietet Ihnen viele verschiedene Stabilisations- und Koordinationsgeräte: Trampoline, Sypoba, Balanco, MFT-Balanceboard, Airex, Velo, Powerrack, etc. Unseren Trainingsraum können Sie zur Unterstützung Ihrer Rehabilitation während der Behandlungszeit kostenlos nutzen.</p>
						</div>
					</div>
					<div className='section'>
				        {/* <div className="ui container">
						    <PhotoGallery />
                       </div> */}
					   	<div className="ui container">
						    <DynamicGallery />
                       </div>
				    </div>
                </div>

				<div>
					<Footer className="footer" />
				</div>
			</div>
		);
	}
}

